import 'core-js/stable';
import 'regenerator-runtime/runtime';

import './helpers/polyfills';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueTouch from 'vue-touch';
import Vuelidate from 'vuelidate';
import Toasted from 'vue-toasted';
import VTooltip from 'v-tooltip';
import Tabs from 'vue-tabs-component';
import moment from 'moment';
import VueMoment from 'vue-moment';
import VueMatchHeights from 'vue-match-heights';
import AsyncComputed from 'vue-async-computed';
import VueTheMask from 'vue-the-mask';
import MobileDetect from 'mobile-detect';
import App from './App.vue';
import { BootstrapVue } from 'bootstrap-vue'
import checkout from './checkout/';
import router from './router';
import store from './store';
import i18n from './i18n';
import ClickOutside from './directives/ClickOutside';
import sanitizeHtml from 'sanitize-html';
import interceptorsSetup from './helpers/interceptors';
import autofocus from 'vue-autofocus-directive';
import feather from 'vue-icon'
import VeeValidate from 'vee-validate';
import VueTelInput from 'vue-tel-input'
import Simplert from 'vue2-simplert-plugin'

import 'vue-tel-input/dist/vue-tel-input.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue2-simplert-plugin/dist/vue2-simplert-plugin.css'
import './styles/main.scss';

Vue.use(checkout, {
  store,
  mode: 'broker',
  style: 'affiliate',
  isOffer: true
});

const md = new MobileDetect(window.navigator.userAgent);
Vue.prototype.$isMobile = md.mobile();
Vue.prototype.$isMobileOrTablet = !!(md.mobile() || md.tablet());

Vue.use(feather, 'v-icon')
Vue.use(BootstrapVue)
Vue.use(VueTelInput)
Vue.use(VueMeta);
Vue.use(VueTouch);
Vue.use(Vuelidate);
Vue.use(Toasted, {
  duration: 3000,
  position: 'top-right',
  theme: 'bubble',
});
Vue.use(VeeValidate, {
  classes: true
});
Vue.use(VTooltip);
VTooltip.options.defaultTrigger = 'click hover focus'
Vue.use(Tabs);
Vue.use(VueTheMask);
Vue.use(VueMoment, { moment });
Vue.use(VueMatchHeights);
Vue.use(AsyncComputed);
Vue.use(Simplert)

Vue.filter('spaceSlashes', function(str) {
  // Use regular expression to add spaces around slashes
  return str.replace(/([^ \/])(\/)([^ \/])/g, '$1 $2 $3');
});

Vue.directive('autofocus', autofocus);

Vue.sanitizeHtml = Vue.prototype.$sanitizeHtml = function (dirty) {
  return sanitizeHtml(dirty, {
    allowedTags: [
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
      'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
      'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'span'
    ],
    allowedAttributes: {
      a: [ 'href', 'name', 'target' ],
      img: [ 'src' ]
    }
  })
}

interceptorsSetup();

Vue.directive('click-outside', ClickOutside);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
