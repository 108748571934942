<template>
  <div>
    <p>
      Wir freuen uns, dass Sie Ihr CyberDirekt Kunden-Portal nutzen. Als Ihr
      Partnerin der Absicherung digitaler Risiken ist es unser Ziel uns stets zu
      verbessern und unseren Service für Sie weiterzuentwickeln. Mit einer
      Bewertung unterstützen Sie uns dabei.
    </p>
    <p>
      Hinterlassen Sie uns in nur 30 Sekunden eine Bewertung bei TrustPilot:
      <a
        class="link"
        href="https://de.trustpilot.com/evaluate/cyberdirekt.de"
        target="_blank"
        @click="onRateLinkClick()"
        >CyberDirekt bewerten!</a
      >
    </p>

    <a
      href="https://de.trustpilot.com/evaluate/cyberdirekt.de"
      target="_blank"
      @click="onRateLinkClick()"
    >
      <img src="@/assets/images/trustpilot-logo.png" alt="trustpilot" />
    </a>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions(['SET_ACCOUNT_DATA']),

    async setSurvayPopup(bool) {
      this.SET_ACCOUNT_DATA({ showSurveyPopUp: bool })
    },

    onClose() {
      this.setSurvayPopup(false)
    },

    onRateLinkClick() {
      this.setSurvayPopup(false)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  max-width: 220px;
}
</style>
