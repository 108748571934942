<template>
  <transition name="modal">
    <div
      v-if="show"
      class="modal-mask"
      @mousedown="closeModal()"
      :class="modalClass"
      :style="overlayRightPosition"
    >
      <div class="modal-wrapper">
        <div class="modal-container" @mousedown="$event.stopPropagation()">
          <div class="form-group">
            <label for="reason">Ihr Anliegen</label>
            <textarea class="form-control"
                      name="reason"
                      id="reason"
                      rows="5"
                      v-model="questionText"
                      >
            </textarea>

            <label class="my-2 mt-4"> Kontaktdaten </label>
            <div class="my-2">
              <input class="form-control"
                  type="text"
                  placeholder="Telefon"
                  v-model="senderPhone">
            </div>
            <div class="my-2">
              <input class="form-control"
                  type="text"
                  placeholder="E-Mail"
                  v-model="senderEmail">
            </div>
            <button class="btn mt-4 btn-secondary"
                    type="button"
                    @click="sendAnfrage"
                    :disabled="isDisabled"
                    >
              <span>Anfrage abschicken</span>
            </button>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>

const $html = document.querySelector("html");
const $body = document.querySelector("body");
const scrollDiv = document.createElement("div");

  scrollDiv.className = "scrollbar-measure";
  $body.appendChild(scrollDiv);

  const getScrollBarWidth = () => {
    const scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    $body.appendChild(scrollDiv);

    const scrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    $body.removeChild(scrollDiv);

    return scrollBarWidth;
  };

  const getBodyScrollBarWidth = () => window.innerWidth - $body.clientWidth;

export default {
  name: "RuckFrageModal",
  data() {
    return {
      questionText: null,
      senderPhone: null,
      senderEmail: null,
      modalClass: "modal-mask--",
      scrollBarWidth : getScrollBarWidth(),
      scroll: 0
    };
  },
  computed: {
    iconColor() {
        return "default";
    },
    overlayRightPosition() {
      return {
        right: !this.$isMobile ? `-${this.scrollBarWidth + 1}px` : 0,
      };
    },
    isDisabled() {
      if(this.questionText === null || this.questionText === '') return true
      return false
    }
  },
  watch: {
    show (newValue) {
      $body.style.paddingRight = newValue ? getBodyScrollBarWidth() + 'px' : '';
    }
  },
  props: {
    show: Boolean,
  },
  methods: {
    sendAnfrage() {
      this.$emit('sendAnswer', {
        questionText: this.questionText,
        senderPhone: this.senderPhone,
        senderEmail: this.senderEmail
      });
    },
    closeModal() {
      this.$emit('closeItself')
    },
    scrollTo() {
      $body.style.position = "static";
      $body.style.top = 0;
      $body.style.overflow = "visible";

      if (!this.scroll) return;

      $html.scrollTop = this.scroll;
      $body.scrollTop = this.scroll;

      this.scroll = 0;
    },
  },
  mounted() {
    $body.style.paddingRight = this.show ? getBodyScrollBarWidth() + 'px' : '';

    this.scroll = $html.scrollTop || $body.scrollTop || this.scroll;
    $body.style.top = "-" + this.scroll + "px";
  },
  destroyed() {
    $body.removeAttribute("style");
    $body.classList.remove("modal-open");
    this.scrollTo();
  },
};
</script>

<style lang="scss">
.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

.modal-open {
  overflow: hidden;
  width: 100%;
  padding-right: 17px;
}
</style>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.modal-mask {
  position: fixed;
  z-index: 1005;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    padding: 15px;
  }
}

.modal-container {
  position: relative;
  max-width: 356px;
  width: 100%;
  border: 1px solid $table-border-color;
  box-sizing: border-box;
  margin: auto;
  padding: 22px 25px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-header {
  padding: 0;

  h3 {
    margin: 0 0 10px;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $dark-blue;
  }
}

.modal-body {
  margin: 20px 0;
  padding: 0;
}

.modal-footer {
  padding: 0;
}

.modal-default-button {
  float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
