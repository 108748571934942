<template>
  <div class="welcome-modal">
    <div class="mb-5 flex justify-content-start">
      <LangSwitch :selected='i18' :isMenu="false"/>
    </div>
    <div class="welcome-modal__row">
      <h3 class="">
        {{ $t("welcome_modal.headline_1") }}
      </h3>
    </div>
    <div class="welcome-modal__row">
      <h4>
        {{ $t("welcome_modal.headline_2") }}
      </h4>
      <ul>
        <li> {{ $t("welcome_modal.list_1") }}</li>
        <li> {{ $t("welcome_modal.list_2") }}</li>
        <li> {{ $t("welcome_modal.list_3") }}</li>
        <li> {{ $t("welcome_modal.list_4") }}</li>
      </ul>
    </div>
    <div v-if="!isWithoutAvv" class="welcome-modal__row">
      <h4>
        {{ $t("welcome_modal.headline_1") }}
      </h4>
      <p>
        {{ $t("welcome_modal.text_1") }}
      </p>
    </div>
    <div v-if="!isWithoutAvv" class="welcome-modal__row welcome-modal__row-buttons">
      <button @click="openAvv"
              type="button"
              class="btn btn__welcome-modal"
      >
        <span>
          {{ $t('welcome_modal.btn_1') }}    
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25 24" fill="none">
            <path d="M12.8137 4.92893L19.8848 12M19.8848 12L12.8137 19.0711M19.8848 12H4.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>       
      </button>
      <button @click="exit"
              type="button"
              class="btn btn__welcome-modal"
       >
        <span>
          {{ $t('welcome_modal.btn_2') }}    
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25 24" fill="none">
            <path d="M12.8137 4.92893L19.8848 12M19.8848 12L12.8137 19.0711M19.8848 12H4.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>       
      </button>
    </div>
    <div class="welcome-modal__row">
      <h4>
        {{ $t("welcome_modal.headline_4") }}
      </h4>
      <p>
        {{ $t("welcome_modal.text_2") }}
      </p>
    </div>
    <div class="welcome-modal__row welcome-modal__row-buttons">
      <button type="button" class="btn btn__welcome-modal btn__welcome-modal--light">
        <span>
          <a :title="$t('common.emailWithCapital')"
            rel="nofollow noopener"
            href="mailto:training@cyberdirekt.de"
          >
            {{ $t('welcome_modal.btn_3') }}
          </a>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25 24" fill="none">
            <path d="M12.8137 4.92893L19.8848 12M19.8848 12L12.8137 19.0711M19.8848 12H4.5" stroke="#EB5D0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>       
      </button>
      <button type="button" class="btn btn__welcome-modal btn__welcome-modal--light">
        <span>
          <a :title="$t('common.call_us')"
            rel="nofollow noopener"
            href="tel:03040366036"
          >
            {{ $t('welcome_modal.btn_4') }}
          </a>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25 24" fill="none">
            <path d="M12.8137 4.92893L19.8848 12M19.8848 12L12.8137 19.0711M19.8848 12H4.5" stroke="#EB5D0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>       
      </button>
    </div>
  </div>
</template>

<script>
import LangSwitch from '@/components/langSwitch/LangSwitch.vue';



export default {
  components: {
    // AvvBody
    LangSwitch, 
  },
  props: {
    isWithoutAvv: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      avvAccepted: false
    }
  },
  computed: {
    i18(){
      return this.$i18n.locale
    },
  },
  created() {
  },
  methods: {
    async openAvv() {
      await this.$store.dispatch('CLOSE_MODAL');

      this.$store.dispatch('OPEN_MODAL', {header: '', componentName: 'Avv', footer: '', modalStyle: 'lg'});
    },
    exit() {
      this.$store.dispatch('LOG_OUT')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.welcome-modal {

  h3 {
    font-size: 29px;
    font-weight: 900;
  }

  h4 {
    font-size: 19px;
    font-weight: 800;
  }

  p, ul {
    font-size: 0.875rem;
  }
  
  &__row {
    margin-bottom: 2rem;
  }

  &__row-buttons {
    // border-bottom: 0.5px dashed blue;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    justify-content: space-around;
  }

  .btn {

    &.btn__welcome-modal {
      flex: 1;
      display: flex;
      max-width: 293px;
      // max-height: 48px;
      padding: 8px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      flex-shrink: 0;
      border-radius: 4px;
      background: var(--button-1, linear-gradient(360deg, #E54C00 0%, #F26722 100%));
      box-shadow: 0px 4px 12px 0px rgba(242, 103, 34, 0.40);
      color: #FFF;
      
      &--light {
        border: 1px solid #EB5D0B !important;
        color: #EB5D0B;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 4px 12px 0px rgba(242, 103, 34, 0.40);
      }

      span {
        display: flex;
        align-items: center;

        a {
          color: #EB5D0B;
          text-decoration: none;
        }
      }
    }
  }
 
}

</style>