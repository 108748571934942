import Vue from 'vue';

export default {
  patch(userId, data) {
    return Vue.http.patch(`customers/${userId}`, data);
  },
  getContract(userId) {
    return Vue.http.get(`customers/${userId}/registrations`);
  }
};
