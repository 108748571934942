<template>
  <select :id="id || ''" class="form-control" :value="value" @change="updateValue">
    <slot/>
  </select>
</template>

<script>
export default {
  props: {
    id: String,
    dataType: String,
    value: {
      type: [Number, String, Boolean]
    }
  },
  methods: {
    updateValue(e) {
      if (this.dataType === 'Number') {
        this.$emit('input', Number(e.target.value))
      } else {
        this.$emit('input', e.target.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
select {
  border: none;
  background: #fff url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuMjg2IDEuMDQ3IDYgNS43NjFsNC43MTQtNC43MTQiIHN0cm9rZT0iIzMxQTlFMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=") no-repeat right 0.75rem center;
  background-size: 12px 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 48px;
}
</style>
