import Vue from 'vue';

const BASE_URL = 'phishing';

export default {
  getGroups() {
    return Vue.http
      .get(`${BASE_URL}/get-groups`)
      .then(response => response.data)
  },
  getGroup(id) {
    return Vue.http
      .get(`${BASE_URL}/get-group/${id}`)
      .then(response => response.data)
  },
  createGroup(targets) {
    return Vue.http
      .post(`${BASE_URL}/create-group`, {
        prefix: 'default',
        targets
      })
      .then(response => response.data)
  },
  updateGroup(id, name, targets) {
    return Vue.http
      .post(`${BASE_URL}/update-group`, {
        id,
        name,
        targets
      })
      .then(response => response.data)
  },
  getCampaign(id) {
    return Vue.http
      .get(`${BASE_URL}/get-campaign/${id}?view=summary`)
      .then(response => response.data)
  },
  getCampaigns() {
    return Vue.http
      .get(`${BASE_URL}/get-cd-campaigns`)
      .then(response => response.data)
  },
  startCampaign(campaignList) {
    return Vue.http
      .post(`${BASE_URL}/start-campaign`, {campaignList})
      .then(response => response.data)
  },
  uploadFile(formData) {
    return Vue.http
        .post(`${BASE_URL}/upload-file`, formData)
        .then(response => response.data)
  }
};
