<template>
  <div class="page__holder">
    <h1>{{ $t(title) }}</h1>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      title: 'page.home_title',
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
    }
  },
};
</script>
