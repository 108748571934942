import axios from 'axios';
import store from '../store';
import router from '../router';
import { get as getValue } from 'lodash';

export default function setup() {
  axios.interceptors.request.use(config => {
    const token = store.getters.token;

    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  }, error =>  Promise.reject(error));

  axios.interceptors.response.use(
    response => response,
    error => {
      const errorCode = getValue(error, 'response.data.error.code');
      const currentPatch = router.history.current.fullPath;

      if (errorCode === 'AUTHORIZATION_REQUIRED' && currentPatch.indexOf('/login') === -1) {
        store.commit('CLEAR_SESSION_DATA');
        store.commit('CLEAR_ACCOUNT_DATA');
        store.commit('CLEAR_SELECTED_USER');
        store.commit('CLEAR_SELECTED_COURSE');

        router.push({
          path: '/login',
          query: { next: router.history.current.fullPath },
        });
      }

      return Promise.reject(error);
    }
  );
}
