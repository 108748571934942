<template>
  <div class="loader-container">
    <div class="loader"></div>
  </div>
</template>

<script>

export default {
  name: "Loader",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; /* Ensure it covers the parent container */
  flex-direction: column;
  top: 0;
  left: 0;
}

.logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid lighten($primary, 30%);
  border-right-color: $orange;
  animation: l2 1s infinite linear;
}
@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
</style>
