import {noop} from 'lodash';
import phishingApi from '@/api/phishing.js';

import EventBus, {EVENT_TYPES} from '@/components/shared/EventBus.js';

export const MUTATION_TYPES = {
  GET_CAMPAIGN_REQUEST: 'GET_CAMPAIGN_REQUEST',
  GET_CAMPAIGN_SUCCESS: 'GET_CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_FAILURE: 'GET_CAMPAIGN_FAILURE',

  GET_CAMPAIGNS_REQUEST: 'GET_CAMPAIGNS_REQUEST',
  GET_CAMPAIGNS_SUCCESS: 'GET_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_FAILURE: 'GET_CAMPAIGNS_FAILURE',

  START_CAMPAIGN_REQUEST: 'START_CAMPAIGN_REQUEST',
  START_CAMPAIGN_SUCCESS: 'START_CAMPAIGN_SUCCESS',
  START_CAMPAIGN_FAILURE: 'START_CAMPAIGN_FAILURE',

  GET_GROUPS_REQUEST: 'GET_GROUPS_REQUEST',
  GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
  GET_GROUPS_FAILURE: 'GET_GROUPS_FAILURE',

  GET_GROUP_REQUEST: 'GET_GROUP_REQUEST',
  GET_GROUP_SUCCESS: 'GET_GROUP_SUCCESS',
  GET_GROUP_FAILURE: 'GET_GROUP_FAILURE',

  CREATE_GROUP_REQUEST: 'CREATE_GROUP_REQUEST',
  CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
  CREATE_GROUP_FAILURE: 'CREATE_GROUP_FAILURE',

  UPDATE_GROUP_REQUEST: 'UPDATE_GROUP_REQUEST',
  UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
  UPDATE_GROUP_FAILURE: 'UPDATE_GROUP_FAILURE',

  UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE: 'UPLOAD_FILE_FAILURE',
};

const initState = {
  group: null
};

const state = {...initState};

const getters = {
  group: state => state.group,
};

const actions = {
  removeTarget({commit, state}, email) {
    commit(MUTATION_TYPES.UPDATE_GROUP_REQUEST);

    state.group.targets = state.group.targets.filter(target => target.email !== email);

    return phishingApi
      .updateGroup(state.group.id, state.group.name, state.group.targets)
      .then((group) => {
        commit(MUTATION_TYPES.UPDATE_GROUP_SUCCESS, group);
        EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
          type: 'success',
          text: 'E-Mail-Liste wurde gelöscht.',
        });
      })
      .catch(() => {
        commit(MUTATION_TYPES.UPDATE_GROUP_FAILURE);
        EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
          type: 'error',
          title: 'Error',
          text: 'E-Mail-Liste konnte nicht gelöscht werden.',
        });
      });
  },
  addTarget({commit, state}, target) {
    if (!state.group || !state.group.id) {
      commit(MUTATION_TYPES.CREATE_GROUP_REQUEST);

      return phishingApi
        .createGroup([target])
        .then((createdGroup) => {
          commit(MUTATION_TYPES.CREATE_GROUP_SUCCESS, createdGroup);
          EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
            type: 'success',
            text: 'E-Mail-Liste wurde hinzugefügt.',
          });
        })
        .catch(() => {
          commit(MUTATION_TYPES.CREATE_GROUP_FAILURE);
          EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
            type: 'error',
            title: 'Error',
            text: 'E-Mail-Liste konnte nicht aktualisiert werden',
          });
        });
    } else {
      commit(MUTATION_TYPES.UPDATE_GROUP_REQUEST);

      state.group.targets = state.group.targets || [];

      if (state.group && state.group.targets && state.group.targets.length) {
        const emailExists = state.group.targets.some((existingTarget => existingTarget.email === target.email));

        if (emailExists) {
          commit(MUTATION_TYPES.UPDATE_GROUP_FAILURE);
          EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
            type: 'error',
            title: 'Error',
            text: 'E-Mail-Adresse ist bereits in der E-Mail-Liste',
          });

          return Promise.reject('email_exists');
        }
      }

      state.group.targets.push(target);

      return phishingApi
        .updateGroup(state.group.id, state.group.name, state.group.targets)
        .then((group) => {
          commit(MUTATION_TYPES.UPDATE_GROUP_SUCCESS, group);
          EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
            type: 'success',
            text: 'E-Mail-Liste wurde hinzugefügt.',
          });
        })
        .catch(() => {
          commit(MUTATION_TYPES.UPDATE_GROUP_FAILURE);
          EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
            type: 'error',
            title: 'Error',
            text: 'E-Mail-Liste konnte nicht aktualisiert werden',
          });
        });
    }
  },
  getGroup({commit}) {
    commit(MUTATION_TYPES.GET_GROUPS_REQUEST);
    return phishingApi
      .getGroups()
      .then((groups) => {
        if (groups && groups.length) {
          return phishingApi
            .getGroup(groups[0].id)
            .then((group) => {
              commit(MUTATION_TYPES.GET_GROUP_SUCCESS, group);
              return group;
            })
        } else {
          commit(MUTATION_TYPES.GET_GROUP_SUCCESS, groups);
          return undefined;
        }
      })
      .catch(() => {
        commit(MUTATION_TYPES.GET_GROUPS_FAILURE);
        EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
          type: 'error',
          title: 'Error',
          text: 'E-Mail-Liste konnte nicht geladen werden',
        });
      });
  },
  getCampaign({commit}, id) {
    commit(MUTATION_TYPES.GET_CAMPAIGN_REQUEST);
    return phishingApi
      .getCampaign(id)
      .then((campaigns) => {
        commit(MUTATION_TYPES.GET_CAMPAIGN_SUCCESS, campaigns);
        return campaigns;
      })
      .catch(() => {
        commit(MUTATION_TYPES.GET_CAMPAIGN_FAILURE);
        EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
          type: 'error',
          title: 'Error',
          text: 'Phishing-Kampagne konnte nicht geladen werden',
        });
      });
  },
  getCampaigns({commit}) {
    commit(MUTATION_TYPES.GET_CAMPAIGNS_REQUEST);
    return phishingApi
      .getCampaigns()
      .then((campaigns) => {
        commit(MUTATION_TYPES.GET_CAMPAIGNS_SUCCESS, campaigns);
        return campaigns;
      })
      .catch(() => {
        commit(MUTATION_TYPES.GET_CAMPAIGNS_FAILURE);
        EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
          type: 'error',
          title: 'Error',
          text: 'Phishing-Kampagnen konnte nicht geladen werden',
        });
      });
  },
  startCampaign({commit}, campaigns) {
    commit(MUTATION_TYPES.START_CAMPAIGN_REQUEST);
    return phishingApi
      .startCampaign(campaigns)
      .then((campaigns) => {
        commit(MUTATION_TYPES.START_CAMPAIGN_SUCCESS, campaigns);
      })
      .catch(() => {
        commit(MUTATION_TYPES.START_CAMPAIGN_FAILURE);
        EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
          type: 'error',
          title: 'Error',
          text: 'Phishing-Kampagne konnte nicht gestartet werden',
        });
      });
  },
  uploadFile({commit}, file) {
    commit(MUTATION_TYPES.UPLOAD_FILE_REQUEST);
    let formData = new FormData();
    formData.append("file", file);
    return phishingApi
        .uploadFile(formData)
        .then((response) => {
          return response
          commit(MUTATION_TYPES.UPLOAD_FILE_SUCCESS, formData);
        })
        .catch(() => {
          commit(MUTATION_TYPES.UPLOAD_FILE_FAILURE);
          EventBus.$emit(EVENT_TYPES.NOTIFICATION, {
            type: 'error',
            title: 'Error',
            text: 'Error file not uploaded',
          });
        });
  }
};

const mutations = {
  [MUTATION_TYPES.GET_GROUP_SUCCESS](state, group) {
    state.group = group;
  }
  // [MUTATION_TYPES.INVITE_SUCCESS](state, employee) {
  //   state.customers.push(employee);
  // },
  // [MUTATION_TYPES.GET_CUSTOMERS_SUCCESS](state, customers) {
  //   state.customers = customers;
  // },
  // [MUTATION_TYPES.DELETE_CUSTOMER_SUCCESS](state, customerId) {
  //   const deleteIdx = state.customers.findIndex(({id}) => id === customerId);
  //   if (deleteIdx > 0) {
  //     state.customers.splice(deleteIdx, 1);
  //   }
  // },
};

Object.keys(MUTATION_TYPES)
  .forEach((type) => {
    if (Object.keys(mutations).indexOf(type) === -1) {
      mutations[type] = noop;
    }
  });

export default {
  state,
  getters,
  actions,
  mutations,
};
