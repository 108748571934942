<template>
  <div>
    <div class="d-print-none" v-if="inputs.paymentMethod === 'directDebit'">
      <div class="form-row row gap-md">
        <div
          class="col-12 col-md-6 cd-control form-group"
          :class="getFieldClass('sepaCompanyName')"
        >
          <label class="cd-label" for="sepaCompanyName"
            >Vollständiger Firmenname *</label
          >
          <input
            id="sepaCompanyName"
            :disabled="paymentMethodDisable"
            type="text"
            class="form-control cd-input"
            placeholder="Muster GmbH & Co.KG"
            name="sepaCompanyName"
            v-model="sepa.company"
            v-validate="
              `${inputs.paymentMethod === 'directDebit' ? 'required' : ''}|max:${offer.insurer && offer.insurer.includes('victor') ? 60 : 255}`
            "
          />
        </div>
        <div class="col-12 col-md-6 d-flex align-items-end btn-wrap form-group">
          <ButtonComponent btn-style="accent" @click.native="autoFillForm"
            >Daten von oben übernehmen</ButtonComponent
          >
        </div>
      </div>

      <div class="form-row row gap-md">
        <div class="col-12 col-md-6 cd-control form-group" :class="getFieldClass('sepaFirstname')">
          <label class="cd-label" for="sepaFirstname">Vorname</label>
          <input
            class="form-control cd-input"
            type="text"
            id="sepaFirstname"
            name="sepaFirstname"
            v-model="sepa.firstname"
            placeholder="Ihr Vorname"
          />
        </div>
        <div class="col-12 col-md-6 cd-control form-group" :class="getFieldClass('sepaLastname')">
          <label class="cd-label" for="sepaLastname">Nachname</label>
          <input
            type="text"
            class="form-control cd-input"
            id="sepaLastname"
            name="sepaLastname"
            v-model="sepa.lastname"
            placeholder="Ihr Nachname"
          />
        </div>
      </div>

      <div class="form-row row gap-md">
        <div class="col-12 col-md-6 cd-control form-group" :class="getFieldClass('sepaStreet')">
          <label class="cd-label" for="sepaStreet"
            >Straße (inkl. Hausnummer) *</label
          >
          <input
            type="text"
            class="form-control cd-input"
            id="sepaStreet"
            name="sepaStreet"
            v-validate="
              inputs.paymentMethod === 'directDebit' ? 'required|min:1' : ''
            "
            v-model="sepa.street"
            placeholder="Musterstrasse 1"
          />
        </div>
      </div>

      <div class="form-row row gap-md mb-5">
        <div class="col-12 col-md-4 cd-control form-group" :class="getFieldClass('sepaPostal')">
          <label class="cd-label" for="sepaPostal">PLZ *</label>
          <input
            type="text"
            class="form-control cd-input"
            id="sepaPostal"
            name="sepaPostal"
            v-validate="
              inputs.paymentMethod === 'directDebit' ? 'required|min:1' : ''
            "
            v-model="sepa.postal"
            :placeholder="isAonOffer ? '4030' : '10115'"
          />
        </div>
        <div class="col-12 col-md-4 cd-control form-group" :class="getFieldClass('sepaCity')">
          <label class="cd-label" for="sepaCity">Stadt *</label>
          <input
            type="text"
            class="form-control cd-input"
            id="sepaCity"
            name="sepaCity"
            v-validate="
              inputs.paymentMethod === 'directDebit' ? 'required|min:1' : ''
            "
            v-model="sepa.city"
            :placeholder="isAonOffer ? 'Wien' : 'Berlin'"
          />
        </div>
        <div class="col-12 col-md-4 form-group cd-control" :class="getFieldClass('iban')">
          <label class="cd-label" for="iban">IBAN *</label>
          <the-mask
            id="iban"
            v-validate="
              inputs.paymentMethod === 'directDebit' ? 'required|iban' : ''
            "
            v-model="sepa.iban"
            name="iban"
            class="form-control cd-input"
            mask="AA## #### #### #### #### ##"
            :placeholder="isAonOffer? 'AT48 3200 0000 1234 5864' : 'DE12 5001 0517 0648 4898 90'"
          />
        </div>
      </div>

      <div v-if="offer.insurer" class="info mb-5">
        <div class="font-weight-bold mb-2">
          SEPA-Lastschriftmandant für SEPA-Basislastschriften <br />
          Gläubigeridentifikation: {{ getSepaMandatText().id }}
        </div>

        <p>
          Ich ermächtige/Wir ermächtigen die {{ getSepaMandatText().name }},
          Zweigniederlassung für die Bundesrepublik Deutschland, Zahlungen von
          meinem/unserem Konto mittels Lastschrift einzuziehen. Zugleich weise
          ich mein/ weisen wir unser Kreditinstitut an, die von der {{ getSepaMandatText().name }} auf mein/unser Konto gezogenen Lastschriften einzulösen.
        </p>
        <p>
          Hinweis: Ich kann/Wir können innerhalb von acht Wochen, beginnend mit
          dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen.
          Es gelten dabei die mit meinem/unserem Kreditinstitut vereinbarten
          Bedingungen.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from '../ui/ButtonComponent.vue'

export default {
  // import validator from parent
  inject: ['parentValidator'],

  name: 'SepaForm',
  components: { ButtonComponent },
  // init validator from parent
  created() {
    this.$validator = this.parentValidator
  },
  props: {
    paymentMethodDisable: {
      type: Boolean,
      default: false
    },
    inputs: {
      type: Object,
      required: true,
      default: () => {}
    },
    offer: {
      type: Object,
      required: true,
      default: () => {}
    },
    insurerSepaId: {
      type: Object,
      default: () => {}
    },
    insurerSepaName: {
      type: Object,
      default: () => {}
    },
    isAonOffer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sepa: {
        company: this.inputs.sepa.company,
        firstname: this.inputs.sepa.firstname,
        lastname: this.inputs.sepa.lastname,
        street: this.inputs.sepa.street,
        postal: this.inputs.sepa.postal,
        city: this.inputs.sepa.city,
        iban: this.inputs.sepa.iban
      }
    }
  },
  computed: {},
  methods: {
    autoFillForm() {
      if (this.offer) {
        this.sepa.company = this.offer.company
        this.sepa.firstname = this.offer.firstname
        this.sepa.lastname = this.offer.lastname
        this.sepa.street = this.offer.street
        this.sepa.postal = this.offer.postal
        this.sepa.city = this.offer.city
      }
    },
    getFieldClass(fieldName) {
      if (!this.fields[fieldName]) {
        return
      }

      return {
        'is-danger': this.errors.has(fieldName),
        touched: this.fields[fieldName].dirty
      }
    },
    getSepaMandatText() {
      if (!this.offer.insurer) return
      // every insurer has its own data now coming from tr20 dynamically, 
      // so we dont need any specific rules here anymore.
      let key = this.offer.insurer

      return {
        name: this.insurerSepaName[key],
        id: this.insurerSepaId[key]
      }
    }
  },
  watch: {
    sepa: {
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.$emit('formChanged', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/form.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/mixins';
.cd-control.form-group, .btn-wrap.form-group {
  margin-bottom: 0;
}

@include media-breakpoint-down(sm) {
    .form-group + .form-group {
        margin-top: 2rem;
    }
}
</style>
