import Vue from 'vue';
import VueI18n from 'vue-i18n';
import de from './lang/de';
import axios from 'axios';
import moment from 'moment';
import store from '../store';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de', // set locale
  fallbackLocale: 'de',
  messages: { de } // set locale messages
});

export default i18n;

const loadedLanguages = ['de']; // our default language that is preloaded

function setI18nLanguage (lang) {
  moment.locale(lang);
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang
}

// init lingo based on user preferences
const userLang = store.getters.language;

if (userLang === 'de') {
  setI18nLanguage('de');
} else {
  loadLanguageAsync(userLang)
}

export async function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.indexOf(lang) > -1) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  const messages = await import(/* webpackChunkName: "lang-[request]" */ `@/i18n/lang/${lang}.json`);

  i18n.setLocaleMessage(lang, messages);
  loadedLanguages.push(lang);

  return setI18nLanguage(lang);
}
