<template>
    <v-touch tag="div"
             @swipeleft="closeMenu"
             @swiperight="openMenu"
             :swipe-options="{ direction: 'horizontal' }"
             class="main-nav"
             :class="{'main-nav--active': mobileMenuOpen}"
             v-if="isVisibile"
             v-click-outside="closeMenu">
        <div class="main-nav__mobile-header">
            <router-link class="main-nav__logo-holder-mobile"
                         area-label="logo"
                         to="/">
                <logo color="dark"/>
            </router-link>
            <div class="main-nav__menu-burger"
                 @click="toggleMenu">
                <icon :name="burgerName"
                      color="dark"></icon>
            </div>
        </div>
        <div class="main-nav__holder"
             v-bind:class="'main-nav__holder-' + partnerLogo"
        >
            <router-link class="main-nav__logo-holder"
                         area-label="logo"
                         to="/">
                <logo/>
            </router-link>
            <v-touch tag="nav"
                     @swipeleft="closeMenu"
                     :swipe-options="{ direction: 'horizontal' }"
                     class="main-nav__content">
                <LangSwitch :selected='i18'/>
                <h2 class="main-nav__title"
                    v-if="isAdmin">E-LEARNING PORTAL</h2>
                <template v-if="!contractNotAllowed">
                    <h2 class="main-nav__title"
                        v-if="isEmployer">{{ $t('menu.general') }}</h2>
                    <ul class="main-nav__item-holder"
                        v-if="isEmployer">
                        <li class="main-nav__item">
                            <router-link to="/contract"
                                        class="main-nav__link">
                                <icon name="info"></icon>
                                {{ $t('menu.contract') }}
                            </router-link>
                        </li>
                    </ul>
                </template>
                <h2 class="main-nav__title"
                    v-if="isEmployer || isManager">{{ $t('menu.employees') }}</h2>
                <ul class="main-nav__item-holder"
                    v-if="isAdmin || isEmployer || isManager">
                    <li class="main-nav__item"
                        v-if="isEmployer || isManager">
                        <router-link to="/users"
                                     class="main-nav__link">
                            <icon name="user"></icon>
                            {{ $t('menu.manage_employees') }}
                        </router-link>
                    </li>
                    <li class="main-nav__item"
                        v-if="isAdmin">
                        <router-link to="/admin/policyholders"
                                     class="main-nav__link">
                            <icon name="user"></icon>
                            {{ $t('menu.manage_customers') }}
                        </router-link>
                    </li>
                    <li class="main-nav__item"
                        v-if="isAdmin">
                        <router-link to="/admin/admins"
                                     class="main-nav__link">
                            <icon name="user"></icon>
                            {{ $t('menu.manage_admins') }}
                        </router-link>
                    </li>
                    <li class="main-nav__item"
                        v-if="isEmployer || isManager">
                        <router-link to="/course-settings"
                                     class="main-nav__link">
                            <icon name="settings"></icon>
                            {{ $t('menu.course-settings') }}
                        </router-link>
                    </li>
                    <li class="main-nav__item"
                        v-if="isEmployer || isManager">
                        <router-link to="/dashboard"
                                     class="main-nav__link">
                            <icon name="dashboard"></icon>
                            {{ $t('menu.dashboard') }}
                        </router-link>
                    </li>
                  <li class="main-nav__item"
                      v-if="isEmployer || isManager">
                    <router-link to="/proofOfInsurance"
                                 class="main-nav__link d-flex">
                        <svg class="icon icon--default" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="3" height="24" />
                          <rect x="24" width="3" height="24" transform="rotate(90 24 0)" />
                          <rect x="7" y="5" width="3" height="3" transform="rotate(90 7 5)" />
                          <rect x="19" y="5" width="3" height="10" transform="rotate(90 19 5)" />
                          <rect x="7" y="5" width="3" height="3" transform="rotate(90 7 5)" />
                          <rect x="19" y="5" width="3" height="10" transform="rotate(90 19 5)" />
                          <rect x="7" y="10" width="3" height="3" transform="rotate(90 7 10)" />
                          <rect x="19" y="10" width="3" height="10" transform="rotate(90 19 10)" />
                          <rect x="7" y="15" width="3" height="3" transform="rotate(90 7 15)" />
                          <rect x="19" y="15" width="3" height="10" transform="rotate(90 19 15)" />
                          <rect x="24" y="21" width="3" height="24" transform="rotate(90 24 21)" />
                          <rect x="21" width="3" height="24" />
                        </svg>
                      {{ $t('menu.proofOfInsurance') }}
                    </router-link>
                  </li>
                </ul>
                <h2 class="main-nav__title"
                    v-if="!isAdmin">{{ $t('menu.training') }}</h2>
                <ul class="main-nav__item-holder"
                    v-if="!isAdmin">
                    <li class="main-nav__item">
                        <router-link to="/trainings"
                                     class="main-nav__link">
                            <icon name="training"></icon>
                            {{ $t('menu.my_trainings') }}
                        </router-link>
                    </li>
                    <!--          <li class="main-nav__item">-->
                    <!--            <router-link to="/certificates"-->
                    <!--                         class="main-nav__link">-->
                    <!--              <icon name="certificate"></icon> {{ $t('menu.certificates') }}-->
                    <!--            </router-link>-->
                    <!--          </li>-->
                  <li class="main-nav__item">
                    <router-link to="/personalCertificate"
                                 class="main-nav__link">
                      <icon name="certificate"></icon> {{ $t('menu.personalCertificate') }}
                    </router-link>
                  </li>
                </ul>
                <h2 class="main-nav__title" v-if="isEmployer || isManager || isTrial">{{ $t('menu.phishing') }}</h2>
                <ul class="main-nav__item-holder"
                    v-if="isEmployer || isManager || isTrial">
                    <li class="main-nav__item">
                        <router-link to="/phishing/your-company"
                                     class="main-nav__link">
                            <icon name="user"></icon>
                            {{ $t('menu.phishing_employees') }}
                        </router-link>
                    </li>
                    <li class="main-nav__item">
                        <router-link to="/phishing/campaigns"
                                     class="main-nav__link">
                            <icon name="email"></icon>
                            {{ $t('menu.phishing_campaign') }}
                        </router-link>
                    </li>
                </ul>
                <h2 class="main-nav__title">{{ $t('menu.web-security-check') }}</h2>
                <ul class="main-nav__item-holder">
                    <li class="main-nav__item">
                        <router-link to="/web-security-check"
                                     class="main-nav__link">
                            <icon name="speedometer"></icon>
                            {{ $t('menu.web-security-check_check-your') }}
                        </router-link>
                    </li>
                </ul>
                <h2 class="main-nav__title">{{ $t('menu.my_account') }}</h2>
                <ul class="main-nav__item-holder main-nav__item-holder--last">
                    <li class="main-nav__item"
                        v-if="!isAdmin">
                        <router-link to="/account-settings"
                                     class="main-nav__link">
                            <icon name="settings"></icon>
                            {{ $t('menu.settings') }}
                        </router-link>
                    </li>
                    <li class="main-nav__item" v-if="isEmployer">
                        <span class="main-nav__link" @click="openAvv">
                            <icon name="article"></icon> AVV
                        </span>
                    </li>
                    <li class="main-nav__item">
                        <router-link to="/logout"
                                     class="main-nav__link">
                            <icon name="logout"></icon>
                            {{ $t('menu.log_out') }}
                        </router-link>
                    </li>
                </ul>
            </v-touch>
            <div class="main-nav__actions">
                <div class="main-nav__actions-holder">
                    <router-link to="/impressum"
                                 title="Impressum"
                                 class="main-nav__action-link">
                        <icon name="contact-phone"></icon>
                    </router-link>
                    <a :title="$t('common.emailWithCapital')"
                       rel="nofollow noopener"
                       href="mailto:training@cyberdirekt.de"
                       class="main-nav__action-link">
                        <icon name="email"></icon>
                    </a>
                    <a :title="$t('common.call_us')"
                       rel="nofollow noopener"
                       href="tel:03040366036"
                       class="main-nav__action-link">
                        <icon name="phone-call"></icon>
                    </a>
                </div>
            </div>
        </div>
    </v-touch>
</template>

<script>
  import Logo from '@/components/logo/Logo.vue';
  import Icon from '@/components/icon/Icon.vue';
  import LangSwitch from '@/components/langSwitch/LangSwitch.vue';
  import {mapGetters} from 'vuex';
  import i18n from '@/i18n';



  export default {
    name: 'main-nav',
    components: {
      Logo,
      Icon,
      LangSwitch
    },
    data() {
      return {
        mobileMenuOpen: false,
        isVisibile: false
      }
    },
    computed: {
      burgerName() {
        return this.mobileMenuOpen ? 'close' : 'menu';
      },
      i18(){
          return this.$i18n.locale
      },
      partnerLogo() {
        return this.$store.state.account.user.lead;
      },
      contractNotAllowed() {
        return this.$store.state.account.user.policy === false;
      },
      ...mapGetters([
        'isAdmin',
        'isEmployer',
        'isManager',
        'isTrial',
        'isEmployee'
      ])
    },
    watch: {
      '$route': {
        handler(route) {
          this.mobileMenuOpen = false;

          if (route && route.matched && route.matched.length) {
            if (route.matched.some(m => m.meta.hideNavigation) || route.path.indexOf('/versicherung') > -1) {
              this.isVisibile = false;
            } else {
              this.isVisibile = true;
            }
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      toggleMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
      },
      openMenu() {
        this.mobileMenuOpen = true;
      },
      closeMenu() {
        this.mobileMenuOpen = false;
      },
      openAvv() {
        this.$store.dispatch('OPEN_MODAL', {header: '', componentName: 'Avv', footer: '', modalStyle: 'lg', closeable: true});
      }
    }
  }
</script>

<style lang="scss">
    @import "@/styles/variables.scss";

    $main-nav-width: 280px;
    $mobile-header-height: 56px;

    .main-nav {
        & + div {
            padding-left: $main-nav-width;
        }

        &__holder {
            background: $dark-blue;
            width: $main-nav-width;
            position: fixed;
            z-index: $z-index-main-nav;
            top: 0;
            left: 0;
            bottom: 0;
        }

        &__mobile-header {
            display: none;
            justify-content: space-between;
            align-items: center;
            position: fixed;
            z-index: $z-index-main-nav;
            top: 0;
            left: 0;
            right: 0;
            padding: 5px;
            text-align: center;
            background: #fff;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
        }

        &__logo-holder {
            display: block;
            background: $dark-blue-1;
            line-height: 0;
            padding: 17px 30px;
            outline: none;
        }

        &__logo-holder-mobile {
            display: inline-block;
            line-height: 0;
            padding: 5px 15px;
            outline: none;
        }

        &__menu-burger {
            padding: 5px 15px;
        }

        &__content {
            position: absolute;
            top: 87px;
            left: 0;
            right: 0;
            bottom: 77px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

        &__title {
            color: $menu-titles;
            text-align: left;
            padding: 5px 30px;
            margin: 0;
            font-size: 0.785rem;
            line-height: 1.25rem;
            text-transform: uppercase;
        }

        &__item-holder {
            margin: 0 0 25px;
            padding: 0;
            list-style: none;
            font-size: 0.875rem;
            line-height: 1.25rem;
                &--last {
                    margin-bottom:100px;
                }
        }

        &__item {
            width: 250px;

            & + & {
                margin-top: 1px;
            }
        }

        &__link {
            display: block;
            text-decoration: none !important;
            color: $menu-items-text;
            padding: 8px 10px 8px 26px;
            border-radius: 0 20px 20px 0;
            line-height: 1.5rem;
            border-style: solid;
            border-width: 0 0 0 4px;
            border-color: transparent;
            outline: none;

            .icon {
                fill: $menu-items-text;
                margin-right: 15px;
            }

            &:hover,
            &:focus,
            &.router-link-active {
                color: #fff;
                background: $dark-blue-2;
                border-color: $secondary;

                .icon {
                    fill: #fff;
                }
            }

            &.router-link-active {
                cursor: default;
            }
        }

        &__actions {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &__actions-holder {
            display: flex;
            flex: 1;
            justify-content: stretch;
            align-items: stretch;
            list-style: none;
            padding: 0;
            margin: 0;
        }

        &__action-link {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            padding: 5px;
            height: 62px;
            outline: none;
            background: $dark-blue-1;
            border: 1px solid transparent;

            .icon {
                fill: $menu-items-text;
            }

            & + & {
                border-left-color: $dark-blue-3;
            }

            &:hover,
            &:focus {
                background: $blue;

                .icon {
                    fill: #fff;
                }
            }

            &[disabled] {
                .icon {
                    fill: $middle-grey;
                    pointer-events: none;
                    cursor: pointer;
                }

                &:hover {
                    background: $dark-blue-1;
                }
            }
        }

        @media screen and (max-width: 991px) {
            &__holder {
                transition: transform 0.3s, opacity 0.3s;
                transform: translate(-100%, 0);
                opacity: 0;
            }

            &__mobile-header {
                display: flex;
            }

            &__content {
                top: $mobile-header-height + 15px;
            }

            &__logo-holder {
                padding: 10px 20px;
                height: $mobile-header-height;
            }

            & + div {
                padding-top: $mobile-header-height;
                transition: padding-left 0.3s;
                padding-left: 0;
            }

            &--active {
                .main-nav__holder {
                    transform: translate(0, 0);
                    opacity: 1;
                }

                & + div {
                    padding-left: $main-nav-width;
                }
            }

            &__title {
                padding: 5px 20px;
            }

            &__link {
                padding-left: 16px;
            }
        }

        @media screen and (max-width: $main-nav-width + 480px) {
            &--active {
                & + div {
                    padding-left: 0;
                }
            }
        }

      &__holder.main-nav__holder-artus {
        display: flex;
        flex-direction: column;

        .main-nav__logo-holder {
          height: auto;
        }

        .main-nav__content {
          position: relative;
          top: auto;
          bottom: auto;
        }
      }
    }
</style>
