<template>
    <span class="icon"
          :class="iconClass"
          aria-hidden="true">
        <svg :height="size"
             :width="size"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="M0 0h24v24H0z" fill="none" stroke="none"></path>
            <path v-for="(iconPath, index) in iconPaths"
                  :class="getClass(index)"
                  :key="iconPath"
                  :d="iconPath">
            </path>
            <animateTransform  v-if="rotate"
                               attributeName="transform"
                               type="rotate"
                               from="0 12 12"
                               to="360 12 12"
                               begin="0s"
                               dur="1.5s"
                               repeatCount="indefinite"></animateTransform>
          </g>
        </svg>
    </span>
</template>

<script>
export default {
  name: 'icon',
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'default'
    },
    colors: {
      type: Array,
      default() {
        return [];
      }
    },
    rotate: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 24
    }
  },
  methods: {
    getClass(index) {
      return this.colors[index] ? `icon--${this.colors[index]}` : null;
    }
  },
  computed: {
    iconClass() {
      return this.color ? `icon--${this.color}` : null;
    },
    iconPaths() {
      switch(this.name) {
        case 'user':
          return [
            'M 16.5 7 C 15.12 7 14 8.12 14 9.5 C 14 10.88 15.12 12 16.5 12 C 17.88 12 18.990234 10.88 18.990234 9.5 C 18.990234 8.12 17.88 7 16.5 7 z M 16.5 14 C 14.67 14 11 14.92 11 16.75 L 11 19 L 22 19 L 22 16.75 C 22 14.92 18.33 14 16.5 14 z',
            'M 9 5 C 7.34 5 6 6.34 6 8 C 6 9.66 7.34 11 9 11 C 10.66 11 11.990234 9.66 11.990234 8 C 11.990234 6.34 10.66 5 9 5 z M 9 13 C 6.67 13 2 14.17 2 16.5 L 2 19 L 9 19 L 9 16.75 C 9 15.9 9.3291406 14.409297 11.369141 13.279297 C 10.499141 13.099297 9.66 13 9 13 z',
          ];
        case 'account':
          return [
            'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z',
          ];
        case 'certificate':
          return [
            'M12 1.73A7.28 7.28 0 0 0 4.73 9 7.28 7.28 0 0 0 12 16.28a7.28 7.28 0 0 0 7.27-7.27 7.28 7.28 0 0 0-7.27-7.28zm-.03 3.3c.7 0 .7 1.75 1.26 2.15.56.42 2.26-.06 2.5.61.21.65-1.45 1.2-1.68 1.85-.22.68.77 2.15.2 2.57-.56.4-1.6-1-2.29-1.02-.7 0-1.79 1.4-2.37.99-.56-.4.47-1.83.26-2.5-.22-.66-1.88-1.26-1.67-1.94.21-.67 1.88-.13 2.45-.53.57-.41.63-2.18 1.34-2.19z',
            'M4.87 13.99l-2.48 5.18a.57.57 0 0 0 .69.8c1.1-.36 2.21-.7 3.31-1.04L7.4 21.9c.17.47.81.52 1.05.09l2.39-4.36a8.7 8.7 0 0 1-5.97-3.63zm14.26 0a8.7 8.7 0 0 1-5.97 3.63l2.39 4.36c.24.44.88.38 1.05-.09l1-2.96 3.32 1.03a.57.57 0 0 0 .7-.8z',
          ];
        case 'dashboard':
          return [
            'M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z',
          ];
        case 'logout':
          return [
            'M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z',
          ];
        case 'settings':
          return [
            'M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z',
          ];
        case 'training':
          return [
            'M11.955 3.474a.469.469 0 0 0-.191.05L1.834 8.49a.469.469 0 0 0-.03.821l9.93 5.932a.469.469 0 0 0 .481 0l9.983-5.973a.469.469 0 0 0-.032-.821L12.18 3.522a.469.469 0 0 0-.226-.048zm9.926 7.607a.157.157 0 0 0-.078.023l-.597.354a.156.156 0 0 0-.076.135c-.006 2.083.007 4.398.004 6.347a.864.864 0 1 0 .914-.032c.007-2.189-.007-4.585-.004-6.67a.158.158 0 0 0-.163-.157z',
            'M19.924 12.107a.235.235 0 0 0-.113.034l-7.155 4.28c-.418.251-.947.251-1.365 0l-7.1-4.24a.238.238 0 0 0-.36.204v1.797h-.003v.197l-.006 2.732v.069l.117.279c.149.343.559.828.95 1.123 1.29.975 3.598 2.053 6.119 2.184.914.047 1.012.045 1.875 0 2.716-.144 4.973-1.205 6.29-2.235.351-.274.742-.749.882-1.072l.119-.28v-.068l-.004-1.142v-3.623a.237.237 0 0 0-.246-.239z',
          ];
        case 'contact-phone':
          return [
            'M22 3H2C.9 3 0 3.9 0 5v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2L24 5c0-1.1-.9-2-2-2zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm3.85-4h1.64L21 16l-1.99 1.99c-1.31-.98-2.28-2.38-2.73-3.99-.18-.64-.28-1.31-.28-2s.1-1.36.28-2c.45-1.62 1.42-3.01 2.73-3.99L21 8l-1.51 2h-1.64c-.22.63-.35 1.3-.35 2s.13 1.37.35 2z',
          ];
        case 'email':
          return [
            'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z',
          ];
        case 'phone-call':
          return [
            'M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z',
          ];
        case 'menu':
          return [
            'M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z',
          ];
        case 'cancel':
        case 'close':
          return [
            'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z',
          ];
        case 'done':
          return [
            'M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z',
          ];
        case 'delete':
          return [
            'M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z',
          ];
        case 'edit':
          return [
            'M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z',
          ];
        case 'plus':
          return [
            'M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z',
          ];
        case 'minus':
          return [
            'M19 13H5v-2h14v2z',
          ];
        case 'article':
          return [
            'M6.36111 17.5833H14V15.9167H6.36111V17.5833ZM6.36111 12.8333H17.6389V11.1667H6.36111V12.8333ZM6.36111 8.08333H17.6389V6.41667H6.36111V8.08333ZM3.66667 22C3.22222 22 2.83333 21.8333 2.5 21.5C2.16667 21.1667 2 20.7778 2 20.3333V3.66667C2 3.22222 2.16667 2.83333 2.5 2.5C2.83333 2.16667 3.22222 2 3.66667 2H20.3333C20.7778 2 21.1667 2.16667 21.5 2.5C21.8333 2.83333 22 3.22222 22 3.66667V20.3333C22 20.7778 21.8333 21.1667 21.5 21.5C21.1667 21.8333 20.7778 22 20.3333 22H3.66667ZM3.66667 20.3333H20.3333V3.66667H3.66667V20.3333Z',
          ];
        case 'info':
          return [
            'M12 1.57C6.24 1.57 1.57 6.24 1.57 12S6.24 22.43 12 22.43 22.43 17.76 22.43 12 17.76 1.57 12 1.57zm2.171 16.165c-.537.212-.964.373-1.285.484a3.39 3.39 0 0 1-1.114.167c-.65 0-1.156-.16-1.516-.476a1.536 1.536 0 0 1-.54-1.207c0-.19.013-.385.04-.582.027-.198.07-.42.13-.67l.672-2.374c.059-.228.11-.444.15-.646.041-.203.06-.39.06-.559 0-.302-.062-.514-.187-.633-.126-.119-.363-.177-.718-.177-.173 0-.351.025-.534.08a7.582 7.582 0 0 0-.467.155l.178-.731c.44-.18.86-.333 1.262-.46a3.73 3.73 0 0 1 1.14-.193c.645 0 1.143.157 1.494.468.348.312.524.717.524 1.215 0 .103-.012.285-.036.545-.024.26-.069.498-.134.716l-.669 2.367c-.055.19-.103.407-.147.65a3.436 3.436 0 0 0-.065.552c0 .315.07.53.211.643.14.114.384.171.73.171.164 0 .347-.029.553-.085.205-.057.354-.107.447-.15zm-.118-9.606c-.312.29-.687.435-1.126.435-.438 0-.816-.145-1.13-.435a1.387 1.387 0 0 1-.471-1.053c0-.41.159-.764.47-1.056a1.6 1.6 0 0 1 1.13-.44c.44 0 .816.146 1.127.44.311.292.468.645.468 1.056 0 .412-.157.764-.468 1.053z',
          ];
        case 'order-asc':
          return [
            'M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z',
          ];
        case 'order-desc':
          return [
            'M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z',
          ];
        case 'prev':
          return [
            'M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z',
          ];
        case 'next':
          return [
            'M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z',
          ];
        case 'sort-asc':
          return [
            'm 3,18 v -2 h 18 v 2 z m 6,-5 v -2 h 12 v 2 z M 15,8 V 6 h 6 v 2 z',
          ];
        case 'sort-desc':
          return [
            'M 3 6 L 3 8 L 21 8 L 21 6 L 3 6 z M 9 11 L 9 13 L 21 13 L 21 11 L 9 11 z M 15 16 L 15 18 L 21 18 L 21 16 L 15 16 z',
          ];
        case 'sync':
          return [
            'M 12,4 V 1 l 4,4 -4,4 V 6 c -3.31,0 -6,2.69 -6,6 0,1.01 0.25,1.97 0.7,2.8 L 5.24,16.26 C 4.46,15.03 4,13.57 4,12 4,7.58 7.58,4 12,4 Z',
            'm 12,18 c 3.31,0 6,-2.69 6,-6 0,-1.01 -0.25,-1.97 -0.7,-2.8 L 18.76,7.74 C 19.54,8.97 20,10.43 20,12 c 0,4.42 -3.58,8 -8,8 v 3 l -4,-4 4,-4 z',
          ];
        case 'play':
          return [
            'M8 5v14l11-7z',
          ];
        case 'pause':
          return [
            'M8.24 4.36c-.73 0-1.33.38-1.33.85V18.8c0 .47.6.85 1.33.85h.57c.74 0 1.33-.38 1.33-.85V5.2c0-.47-.6-.85-1.33-.85zm6.95 0c-.73 0-1.33.38-1.33.85V18.8c0 .47.6.85 1.33.85h.57c.73 0 1.33-.38 1.33-.85V5.2c0-.47-.6-.85-1.33-.85z',
          ];
        case 'search':
          return [
            'M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z',
          ];
        case 'lock':
          return [
            'M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z',
          ];
        case 'unlock':
          return [
            'M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z',
          ];
        case 'stat-bar':
          return [
            'M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z',
          ];
        case 'speedometer':
          return [
            'M20.38 8.57l-1.23 1.85a8 8 0 0 1-.22 7.58H5.07A8 8 0 0 1 15.58 6.85l1.85-1.23A10 10 0 0 0 3.35 19a2 2 0 0 0 1.72 1h13.85a2 2 0 0 0 1.74-1 10 10 0 0 0-.27-10.44zm-9.79 6.84a2 2 0 0 0 2.83 0l5.66-8.49-8.49 5.66a2 2 0 0 0 0 2.83z',
          ];
        case 'arrow-click':
          return [
            'm 15.791801,0.80201888 c -0.41373,0 -0.749308,0.33517402 -0.749308,0.74879102 v 2.353861 c 0,0.413693 0.335654,0.748792 0.749308,0.748792 0.413653,0 0.748791,-0.335175 0.748791,-0.748792 v -2.353861 c 0,-0.413692 -0.335213,-0.74879102 -0.748791,-0.74879102 z M 11.286649,2.4665159 c -0.191696,0 -0.383415,0.07293 -0.529683,0.219109 -0.292536,0.29251 -0.292536,0.766856 0,1.059366 l 1.663981,1.663981 c 0.14623,0.146218 0.337903,0.219625 0.529684,0.219625 0.191707,0 0.383377,-0.07341 0.529683,-0.219625 0.292537,-0.292435 0.292537,-0.766856 0,-1.059366 l -1.664498,-1.663981 c -0.14623,-0.14618 -0.33747,-0.219109 -0.529167,-0.219109 z m 9.153447,0.344682 c -0.191687,1e-5 -0.383415,0.07297 -0.529683,0.219108 l -1.664498,1.664498 c -0.292537,0.29251 -0.292537,0.76634 0,1.05885 0.14623,0.146217 0.338053,0.219625 0.529683,0.219625 0.191782,0 0.383453,-0.07341 0.529684,-0.219625 l 1.664497,-1.663981 c 0.292461,-0.29251 0.292461,-0.766856 0,-1.059367 -0.146344,-0.146179 -0.337996,-0.219117 -0.529683,-0.219108 z m -0.344682,4.647778 c -0.413729,0 -0.749308,0.335768 -0.749308,0.749309 0,0.413616 0.335579,0.748716 0.749308,0.748791 h 2.353862 c 0.413729,0 0.748791,-0.335175 0.748791,-0.748791 0,-0.413617 -0.335137,-0.749309 -0.748791,-0.749309 z M 19.12028,10.300664 c -0.191659,9e-6 -0.383453,0.07297 -0.529683,0.219108 -0.292536,0.292511 -0.292536,0.766857 0,1.059367 l 1.664498,1.663981 c 0.146229,0.146214 0.338053,0.219625 0.529683,0.219625 0.191631,0 0.383377,-0.07341 0.529683,-0.219625 0.292461,-0.292435 0.292461,-0.766857 0,-1.059367 L 19.649963,10.519772 C 19.50362,10.373592 19.311939,10.300654 19.12028,10.300664 Z',
            'M 1.0212208,19.744399 5.4786515,15.287217 2.2986078,13.450562 C 2.0501139,13.307059 1.9055425,13.034306 1.9262063,12.74813 1.9468701,12.461879 2.1292247,12.212653 2.3957429,12.106402 L 16.576187,6.4502972 c 0.277982,-0.1110016 0.595405,-0.045622 0.807097,0.1659746 0.211691,0.2117476 0.277076,0.5290674 0.16614,0.8070239 L 11.893717,21.604128 c -0.106336,0.266494 -0.355584,0.448908 -0.641861,0.46957 -0.286277,0.02081 -0.55913,-0.123821 -0.702571,-0.372368 L 8.7126173,18.521571 4.2554882,22.978753 c -0.1404236,0.140487 -0.3310739,0.219439 -0.5296428,0.219439 -0.1987197,0 -0.3892192,-0.07895 -0.5296428,-0.219439 L 1.0211454,20.803589 c -0.2924612,-0.29251 -0.2924612,-0.76668 7.54e-5,-1.05919 z',
          ];
        default:
          return '';
      }
    },
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  line-height: 0;

  &--default {
    fill: $default-icon-color;
  }

  &--white {
    fill: #ffffff;
  }

  &--primary {
    fill: $primary;
  }

  &--blue {
    fill: $blue-2;
  }

  &--secondary {
    fill: $secondary;
  }

  &--orange {
    fill: $orange;
  }

  &--success {
    fill: $green;
  }

  &--dark {
    fill: $dark-blue;
  }
}
</style>
