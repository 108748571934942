import Vue from 'vue';

export const EVENT_TYPES = {
  NOTIFICATION: 'NOTIFICATION',
  API_ERROR: 'API_ERROR'
};

const EventBus = new Vue();

export default EventBus;
