<template>
  <transition name="modal">
    <div class="modal-mask"
         @mousedown="closeModal()"
         :class="modalClass"
         :style="overlayRightPosition">
      <div class="modal-wrapper">
        <div class="modal-container"
             @mousedown="$event.stopPropagation()">
          <div class="modal-header" :class="{'no-lines':noBorders}">
            <slot name="header">
              <!-- default header -->
            </slot>
            
            <span v-if="closeable" class="modal-cancel"
                  @click="closeModal()">
              <icon name="cancel" :width="iconWidth" :color="iconColor"></icon>
            </span>
          </div>
          
          <div class="modal-body" >
            <slot name="body">
              <component ref="modalBody" 
                :is="bodyComponentName" 
                :isWithoutAvv="isWithoutAvv"
                @close="closeModal" />
            </slot>
          </div>
          
          <div class="modal-footer" :class="{'no-lines':noBorders}" >
            <slot name="footer">
              <!-- default footer
              <button class="modal-default-button"
                      type="button"
                      @click="closeModal()">
                OK
              </button> -->
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from "../icon/Icon.vue";

const $html = document.querySelector("html");
const $body = document.querySelector("body");

import DefaultBody from "./DefaultBody.vue";
import RateUs from "./RateUs.vue";
import Avv from "./Avv.vue";
import WelcomeModal from "./WelcomeModal.vue";
import ScanSuccess from "./ScanSuccess.vue";

const getScrollBarWidth = () => {
  const scrollDiv = document.createElement("div");
  scrollDiv.className = "scrollbar-measure";
  $body.appendChild(scrollDiv);

  const scrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  $body.removeChild(scrollDiv);

  return scrollBarWidth;
};

const getBodyScrollBarWidth = () => window.innerWidth - $body.clientWidth;

export default {
  name: "modal",

  props: {
    modalStyle: {
      type: String
    },
    noBorders: {
      default: false,
      type: Boolean,
    },
    iconWidth: {
      type: Number,
      default: 22,
    },
    preferCloseable: {
      type: Boolean,
      deafault: false
    }
  },
  components: {
    Icon,
    DefaultBody,
    RateUs,
    Avv,
    WelcomeModal,
    ScanSuccess
  },
  data() {
    return {
      scrollBarWidth: getScrollBarWidth(),
      scroll: 0,
      bodyComponentName: "DefaultBody",
    };
  },
  computed: {
    iconColor() {
      if (this.modalStyle === "mobile") {
        return "white";
      } else {
        return "default";
      }
    },
    overlayRightPosition() {
      return {
        right: !this.$isMobile ? `-${this.scrollBarWidth + 1}px` : 0,
      };
    },
    isLg() {
      return this.$store.state.modal.modalStyle === 'lg'
    },
    modalClass() {
      if (this.isLg) {
        return 'modal-mask--lg'
      }
      return this.modalStyle ? "modal-mask--" + this.modalStyle : null
    },
    closeable() {
      return this.$store.state.modal.closeable
    },
    isWithoutAvv() {
      return this.$store.state.modal.isWithoutAvv || false
    }
  },
  methods: {
    closeModal() {
      if (!this.closeable) return
      
      if (this.$refs.modalBody && this.$refs.modalBody.onClose) {
        this.$refs.modalBody.onClose()
      }
      this.$emit("close");
      this.$store.dispatch("CLOSE_MODAL");
    },
    scrollTo() {
      $body.style.position = "static";
      $body.style.top = 0;
      $body.style.overflow = "visible";

      if (!this.scroll) return;

      $html.scrollTop = this.scroll;
      $body.scrollTop = this.scroll;

      this.scroll = 0;
    },
  },
  mounted() {
    this.bodyComponentName = this.$store.state.modal.componentName;
    this.preferCloseable
      ? this.$store.commit('SET_MODAL_PROPERTY', {property: 'closeable', value: true}) 
      : ''

    $body.style.paddingRight = getBodyScrollBarWidth() + "px";

    this.scroll = $html.scrollTop || $body.scrollTop || this.scroll;
    $body.style.position = "fixed";
    $body.style.top = "-" + this.scroll + "px";
    $body.style.overflow = "hidden";
    $body.classList.add("modal-open");
  },
  destroyed() {
    $body.removeAttribute("style");
    $body.classList.remove("modal-open");
    this.scrollTo();
  },
};
</script>

<style lang="scss">
.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

.modal-open {
  overflow: hidden;
  width: 100%;
  padding-right: 17px;
}
</style>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.modal-mask {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    padding: 15px;
  }

  &--big {
    .modal-container {
      max-width: 700px;
      width: 100%;
    }
  }

  &--lg {    
    .modal-container {
      max-width: 990px;
      width: 100%;
    }
  }

  &--xl {    
    .modal-container {
      max-width: 1120px;
      width: 100%;
    }
  }
}

.modal-container {
  position: relative;
  max-width: 356px;
  width: 100%;
  border: 1px solid $table-border-color;
  box-sizing: border-box;
  margin: auto;
  padding: 22px 25px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

::v-deep .modal-cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-header {
  padding: 0;

  h3 {
    margin: 0 0 10px;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $dark-blue;
  }
}
.modal-header.no-lines {
  border: none;
}

.modal-body {
  margin: 20px 0;
  padding: 0;
}

.modal-footer {
  padding: 0;
}

.modal-footer.no-lines {
  border: none;
}

.modal-default-button {
  float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
