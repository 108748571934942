<template>
  <div>
    <h1
      class="MsoNormal-bold"
      align="center"
      style="text-align: center; line-height: normal; font-weight: bold">
        Auftragsdatenverarbeitungsvertrag CyberDirekt GmbH Version 5.40
    </h1>
    <AvvBody></AvvBody>
      <div class="modal-footer">
        <div class="avv-accepted-block">
          <b-form-checkbox
            id="checkbox-avv"
            name="avvAccepted"
            v-model="avvAccepted"
            @change="submitAvv(avvAccepted)"
            :disabled="avvAccepted"
            required
          >
            Ich akzeptiere den Auftragsverarbeitungsvertrag.
          </b-form-checkbox>
          <span v-if="user.orderProcessingCheckedOn">{{`${ $t('common.accepted-on')} ${getAvvDate}`}}</span>
        </div>
    </div>
      <div class="modal-footer__button-box">
        <button
          class="btn btn--primary m-1"
          @click="dwnldPdf()"
          >Jetzt herunterladen</button
        >
        <button
          class="btn btn--default m-1"
          @click="exit()"
          >Abmelden</button
        >
      </div>
    </div>
</template>

<script>
const AvvBody = () => import(/* webpackChunkName: "AVV-BODY" */ './AvvBody.vue')

// import AvvBody from './AvvBody.vue'
import {mapGetters} from 'vuex';
import moment from 'moment'

export default {
  components: {
    AvvBody
  },
  data () {
    return {
      avvAccepted: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
    getAvvDate() {
      return this.user.orderProcessingCheckedOn ? moment(this.user.orderProcessingCheckedOn).format('DD-MM-YYYY') : null
    },
  },
  created() {
    if (this.user.orderProcessingChecked && this.user.orderProcessingCheckedOn) {
      this.avvAccepted = true
    }
  },
  methods: {
    exit() {
      this.$store.dispatch('LOG_OUT')
    },
  
    submitAvv(state) {
      if(!state) {
        console.log('no')
        return
      }
      this.$store.dispatch('SET_ACCOUNT_DATA',
        {
          orderProcessingChecked: this.avvAccepted,
          orderProcessingCheckedOn: Date.now()
        }).then(() => {
          this.closeModal()
        })
    },

    closeModal () {
      this.$store.dispatch('CLOSE_MODAL');
    },
    dwnldPdf() {
      const url = 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/AVV_Cyberdirekt_Gmbh_V_5_40.pdf'
      const label = 'AVV_CyberDirekt_GmbH_V_5_40'
      fetch(url, { method: 'GET' })
          .then(res => {
            return res.blob();
          })
          .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = label;
            document.body.appendChild(a);
            a.click();
            setTimeout(
              _ => { window.URL.revokeObjectURL(url); },
              60000);
            a.remove();
          })
          .catch(err => {
            console.error('err: ', err);
          })
      
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

img {
  max-width: 220px;
}

h1 {
  word-break: break-word;
}

.modal-footer {
  margin-top: 2rem;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;

  &__button-box {
    display: flex;
    justify-content: flex-end;
  }
}
.avv-accepted-block {
  display: flex;
  flex-direction: column;
  span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: left;
    margin-top: 0.2em;
    color: $middle-grey;
  }
}
</style>
