<template>
  <div>
    <h2 v-if="isMenu" class="main-nav__title">{{ $t("menu.lang") }}</h2>
    <div class="main-nav__item">
      <div 
        :class="{ 'main-nav__link': isMenu, 'langswitch__standalone': !isMenu }" 
        @click="changeLang()"
      >
        <div class="language-icons ">
          <img src="@/assets/united-kingdom.svg" v-if="lang==='en'">
          <img src="@/assets/germany.svg" v-else>
        </div>
        <span class="icon icon--default">
        <Transition  mode="out-in">
          <span v-if="lang==='en'" key="a1">
            <span :class="{'active':lang==='en'}"> English </span><span class="m-1"> | </span><span :class="{'active':lang==='de'}"> Deutsch </span>
          </span>
          <span v-else key="a2">
            <span  :class="{'active':lang==='de'}"> Deutsch </span><span class="m-1"> | </span><span :class="{'active':lang==='en'}"> English </span>
          </span>
        </Transition>
        </span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "LangSwitch",
  data() {
    return {
      lang: this.selected
    }
  },
  props: {
    selected: {
      required: true,
    },
    isMenu: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  created() {
    if( this.selected !== 'en' && this.selected !== 'de') {
      this.lang = 'de'
    }
  },
  methods: {
    async changeLang(evt) {
      const lang = this.lang === 'de' ? 'en' : 'de'
      this.lang = lang
      await this.$store.dispatch('CHANGE_LANGUAGE', lang);
    },
  },
  watch: { 
    selected: function(newVal, oldVal) { // not sure why, but selected prop doesnt update on mounting.
      this.lang = newVal
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
.language-icons {
  width: 25px;
  display: inline-block;
  margin-right: 15px;
}
.lang-text {
  padding: 4px;
}
.m-1 {
  padding: 0 3px 0 3px;
}
.lang-path {
  transform: scale(0.1);
}
.main-nav__link {
  background: $dark-blue;

  &:hover,
  &:focus,
  &.router-link-active {
    color: #fff;
    border-color: grey;
    background: $dark-blue;
    transition: all 0.3s ease-out;

    img {
      transform: scale(1.1);
    }
  }
  span {
    display: inline-block;
    color: $menu-items-text;
    font-size: 0.875rem;

    &.active {
      color:#fff
    }
  }
}
.v-enter-active {
  transition: all 0.3s ease-out;
}

.v-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}


.v-enter {
 opacity: 0;
  transform: translateY(20px);
}
.v-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
/* stand alone*/
.langswitch__standalone {
  // display: flex;
  // justify-content: start;

  &:hover,
  &:focus,
  &.router-link-active {
    color: #afafaf;
    border-color: grey;
    transition: all 0.3s ease-out;

    img {
      transform: scale(1.1);
    }
  }

  span {
    display: inline-block;
    color: $menu-items-text;
    font-size: 0.875rem;

    &.active {
      color: $blackColor
    }
  }
}
</style>
