export function getSession() {
  const sessionData = localStorage.getItem('session_data');

  const {
    token = null,
    userId = null,
    ttl = null,
    created = null,
    role = null,
    brokerAgency = null,
    brokerAgencyLogo = null
  } = JSON.parse(sessionData) || {};

  return { token, userId, ttl, created, role, brokerAgency, brokerAgencyLogo };
}

export function setSession(session) {
  localStorage.setItem('session_data', JSON.stringify(session));
}

export function clearSession() {
  localStorage.removeItem('session_data');
}
