import { noop } from 'lodash';
import userApi from '../../api/user';

export const MUTATION_TYPES = {
  GET_CONTRACT_REQUEST: 'GET_CONTRACT_REQUEST',
  GET_CONTRACT_SUCCESS: 'GET_CONTRACT_SUCCESS',
  GET_CONTRACT_FAILURE: 'GET_CONTRACT_FAILURE'
};

const initState = {
  contract: null
};

const state = Object.assign({}, initState);

const getters = {
  contract: state => state.contract,
  allContracts: state => state.allContracts,
  prevContracts: (state) => {
    let contracts = [...state.allContracts]
    // remove last submitted contract
    contracts.pop()
    // sort in desc order (first created ar the end of list)
    contracts = contracts.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    return contracts
  }
};

const actions = {
  getContract({ commit, rootState }) {
    commit(MUTATION_TYPES.GET_CONTRACT_REQUEST);

    const userId = rootState.account.user.id;

    return userApi.getContract(userId)
      .then((payload) => {
        commit(MUTATION_TYPES.GET_CONTRACT_SUCCESS, payload.data);
      }, (error) => {
        commit(MUTATION_TYPES.GET_CONTRACT_FAILURE, error);
        throw error;
      });
  }
};

const mutations = {
  [MUTATION_TYPES.GET_CONTRACT_SUCCESS](state, contract) {
    // setting last submitted contract
    state.contract = contract[contract.length-1];
    state.allContracts = contract
  }
};

Object.keys(MUTATION_TYPES)
  .forEach((type) => {
    if (Object.keys(mutations).indexOf(type) === -1) {
      mutations[type] = noop;
    }
  });

export default {
  state,
  getters,
  actions,
  mutations
};
