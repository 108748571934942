<template>
  <div class="password-complexity">
    <password v-model="password"
              @score="scoreChanged"
              :strength-meter-only="true"/>
    <span class="password-complexity__complexity-holder">
            {{ $t('common.complexity') }}:
            <span class="password-complexity__complexity"
                  v-if="password"
                  :class="'change-password__complexity--' + passwordClass">{{ $t(passwordStrength) }}</span>
          </span>
  </div>
</template>

<script>
  import Password from 'vue-password-strength-meter';
  
  export default {
    name: 'password-complexity',
    components: {
      Password,
    },
    data() {
      return {
        passwordScore: null,
        passwordStrength: '',
        passwordClass: '',
      };
    },
    props: {
      password: String,
    },
    methods: {
      scoreChanged(score) {
        this.passwordScore = score || 0;

        this.passwordStrength = [
          'common.very_weak',
          'common.weak',
          'common.medium',
          'common.strong',
          'common.very_strong'
        ][this.passwordScore];

        this.passwordClass = [
          'red',
          'red',
          'orange',
          'green',
          'green'
        ][this.passwordScore];
        
        this.$emit('score', this.passwordScore);
      }
    },
  }
</script>

<style lang="scss">
  @import "@/styles/variables.scss";
  
  .password-complexity {
    &__complexity-holder {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
    
    &__complexity {
      font-weight: 600;
      
      &--red {
        color: $red;
      }
      
      &--orange {
        color: $orange;
      }
      
      &--green {
        color: $green;
      }
    }
  }
</style>
