// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4yODYgMS4wNDcgNiA1Ljc2MWw0LjcxNC00LjcxNCIgc3Ryb2tlPSIjMzFBOUUwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "select[data-v-121f098b]{border:none;background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat right .75rem center;-webkit-appearance:none;-moz-appearance:none;appearance:none}select.select-border[data-v-121f098b]{border:1px solid #ced4da}", "",{"version":3,"sources":["webpack://./src/checkout/src/components/calculator-v2.0/ui/SelectComponent.vue"],"names":[],"mappings":"AACA,wBACE,WAAA,CACA,qFAAA,CAEA,uBAAA,CACA,oBAAA,CACA,eAAA,CAEA,sCACE,wBAAA","sourcesContent":["\nselect {\n  border: none;\n  background: #fff url(\"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuMjg2IDEuMDQ3IDYgNS43NjFsNC43MTQtNC43MTQiIHN0cm9rZT0iIzMxQTlFMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=\") no-repeat right 0.75rem center;\n  // background-size: 12px 14px;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n\n  &.select-border {\n    border: 1px solid #ced4da;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
