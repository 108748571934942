<template>
  <div class="text-center">
    <!-- is this artus condition even used still? -->
    <img v-if="partnerLogo === 'artus'" class="w-100 custom-logo__artus" src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/images/artus-gruppe.png" :alt="partnerLogo"/>
    <img class="w-100 logo logo--alte-leipziger" src="@/assets/ALH-weiss.svg" v-else-if="color && (partnerLogo === 'alte-leipziger' || lead === 'alte-leipziger')"/>
    <img class="w-100 logo logo--alte-leipziger" src="@/assets/ALH-weiss.svg" v-else-if="partnerLogo === 'alte-leipziger' || lead === 'alte-leipziger'"/>
    <img class="custom-logo" :src="agencyLogo" v-else-if="agencyLogo && agencyKey.includes('artus')"/>
    <img class="custom-logo__aon" v-else-if="isAon" src="@/assets/images/aon_logo.svg" :alt="partnerLogo"/>
    <img v-else class="logo" :src="buildLogoURL()" alt="logo"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'logo',
  methods: {
    buildLogoURL() {
      return this.color ?
        `https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/images/logo_${this.color}.svg` :
        `https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/images/logo_white.svg`;
    }
  },
  computed: {
    lead() {
      return this.$route.query.lead;
    },
    partnerLogo() {
      return this.user.lead;
    },
    ...mapGetters([
      'user',
      'agencyLogo',
      'agencyKey',
      'isAon'
    ])
  },
  props: {
    color: {
      type: String,
      default: 'white'
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

// agencyLogo

.custom-logo {
  max-height: 50px;
}

.custom-logo__aon {
  max-height: 60px;
  @media screen and (max-width: 991px) {
      height: 35px;
    }
}

.main-nav__mobile-header {
  .custom-logo__lead,
  .custom-logo__artus {
    max-width: 100%;
    @media screen and (max-width: 991px) {
      height: 50px;
    }
  }
}

.logo {
  width: 175px;
  height: 36px;

  &--white {
    fill: #fff;
  }

  &--dark {
    fill: $dark-blue;
  }

  &__part-1 {
    fill: $secondary;
  }

  &__part-2 {
    fill: $primary;
  }
  &--alte-leipziger{
    transform: scale(1.2);
    margin-top: 13px;
  }
}
</style>
