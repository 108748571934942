<template>
  <footer class="b-footer-2">
    <div class="container">
      <div class="b-footer-2__inner">
        <div class="b-footer-2__links">
          <div class="b-footer-2__link">
            <a href="https://www.cyberdirekt.de/impressum/">Impressum</a>
          </div>
          <div class="b-footer-2__link">
            <a href="https://www.cyberdirekt.de/agb/">AGB</a>
          </div>
          <div class="b-footer-2__link">
            <a href="https://www.cyberdirekt.de/avv/">AVV</a>
          </div>
          <div class="b-footer-2__link">
            <a href="https://www.cyberdirekt.de/datenschutzhinweise/">Datenschutzhinweise</a>
          </div>
          <div class="b-footer-2__link">
            <a href="https://www.cyberdirekt.de/erstinformation/">Erstinformation</a>
          </div>
        </div>
        <div class="b-footer-2__text">
          Für weitere Informationen besuchen Sie unsere <a href="https://www.cyberdirekt.de/">Website</a>.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
  }
</script>

<style lang="scss" scoped>
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';
.b-footer-2 {
  background: #14192F;
  color: #667075;
  font-size: 0.875em;

  a {
    color: white;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #222f32;
    padding: 2em 0;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__links {
    display: flex;

    .b-footer-2__link + .b-footer-2__link {
      margin-left: 2em;
    }

    @include media-breakpoint-down(xs) {
      display: block;

      .b-footer-2__link + .b-footer-2__link {
        margin-left: 0;
        margin-top: 0.5em;
      }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 1em;
    }

  }
}
</style>
