import Vue from 'vue';

export default {
  getOffer(token) {
    return Vue.http.get(`offers/forCustomer/${token}`);
  },
  patchOffer(offerId, data) {
    return Vue.http.patch(`offers/${offerId}`, data);
  },
  sendFurtherQuestions(data) {
    return Vue.http.post(`offers/send`, data);
  }
};
