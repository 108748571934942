<template>
  <div class="steps">
    <button v-for="step in steps" :key="'offer-step-' + step.id" class="step" :class="{'is-active': step.isActive, 'is-completed': step.isCompleted}" @click="handleStepClick(step)">
      <div v-if="step.isActive" class="step-icon">
        <Icon name="check-bold" :width="15" />
      </div>
      <span class="step__id">
        {{ step.id }}
      </span>
      <span class="step__title">{{ step.title }}</span>
      <svg class="step__arrow" width="25" height="48" viewBox="0 0 25 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0H24.5V48H0L16.7991 27.8411C18.6533 25.616 18.6533 22.384 16.7991 20.1589L0 0Z" fill="#185470"/>
</svg>


<svg class="step__arrow2" width="25" height="48" viewBox="0 0 25 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0H24.5V48H0L16.7991 27.8411C18.6533 25.616 18.6533 22.384 16.7991 20.1589L0 0Z" fill="#fff"/>
</svg>

<svg class="step__arrow3" width="20" height="44" viewBox="0 0 20 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.512195 0L17.8267 18.5925C19.6146 20.5124 19.6146 23.4876 17.8267 25.4075L0.512195 44L0 43.0833L15.7062 26.0699C17.8281 23.7715 17.8281 20.2285 15.7062 17.9301L0 0.916667L0.512195 0Z" fill="#417E9B"/>
</svg>


    </button>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon'

export default { 
  components: {Icon},

  props: ['setCurrentView', 'currentView', 'validateQuestionsStep'],

   data() {
    return {
      steps: [
        {
          id: '1',
          title: "Leistungsübersicht",
          isActive: true,
          isCompleted: false
        },
        {
          id: '2',
          title: "Risikofragen beantworten",
          isActive: false,
          isCompleted: false
        },
        {
          id: '3',
          title: "Antrag einreichen",
          isActive: false,
          isCompleted: false
        }
      ]
    }
   },

   watch: {
    currentView(view) {
      switch (view) {
        case 'marketcomparison':
          this.setActiveStep('1')
          break;
        case 'questions':
          this.setActiveStep('2')
          break;
        case 'submition':
          this.setActiveStep('3')
          break;
      }
    }
   },

   methods: {
    handleStepClick(step) {
      switch (step.id) {
        case '1':
        this.setCurrentView('marketcomparison')
          break;
        case '2':
        this.setCurrentView('questions')
          break;
        case '3':
          if (this.currentView === 'marketcomparison') {
            this.setCurrentView('questions')
            this.validateQuestionsStep()
          } else {
            this.validateQuestionsStep()
          }
          break;
      }
    },

    setActiveStep(stepId) {
      this.steps.forEach(stepItem => {
          if (stepItem.id === stepId ) {
            stepItem.isActive = true
          } else {
            stepItem.isActive = false
          }
        })
    }
   }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.steps {
  position: relative;
  display: flex;
  height: fit-content;

  .step {
    position: relative;
    background: #185470;
    color: #fff;
    font-size: 0.85rem;
    font-weight: 800;
    height: 44px;
    // width: 305px;
    width: 33%;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border: 0;
    padding-right: 2rem;

    &:first-child {
      border-radius: 0.25rem 0 0 0.25rem;
      .step__arrow {
        display: none;
      }
    }

    &:last-child {
      .step__arrow2 {
        display: block;
        position: absolute;
        right: -2px;
      }
    }

    &:not(.is-active) + .step:not(.is-active) {
      .step__arrow3 {
        display: block;
        position: absolute;
        left: -1rem;
        z-index: 1;
      }
    }

    &__arrow {
      position: absolute;
      top: 0;
      left: -20px;
      // width: 24px;
      height: 100%;
      z-index: 1;
    }

    &__arrow2 {
      display: none;
    }

    &__arrow3 {
      display: none;
    }

    &__id {
      display: none;

      @include media-breakpoint-down(sm) {
        display: inline-block;
      }
    }

    &__title {

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    & + .step {
      // margin-left: -19px;
    }

    &.is-active {
      background: #417E9B;

      @include media-breakpoint-down(sm) {
        width: 50%;
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
      }

      .step__arrow path {
        fill: #417E9B;
      }
      
      .step__title {
        display: inline-block;
      }

      .step__id {
        display: none;
      }
    }

    &:not(.is-active ) {
      @include media-breakpoint-down(xs) {
        width: 10%;
      }
    }
  }

  .step-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    max-width: 22px;
    min-width: 22px;
    width: 100%;
    border-radius: 20rem;
    background: #fff;
    margin-right: 0.5rem;
  }
}
</style>
